@import "~antd/dist/antd.css";
@import "~bootstrap/dist/css/bootstrap.min.css";

.App {
  text-align: center;
  background-color: #9170b4;
}

.ant-form-item-label > label {
  color: #9170b4;
}

.form-horizontal .heading {
  display: block;
  font-size: 35px;
  font-weight: 700;
  padding: 35px 0;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 30px;
}

body,
html {
  font-size: 100%;
  padding: 0;
  margin: 0;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
body {
  background: #494a5f;
  font-weight: 500;
  font-size: 1.05em;
  font-family: "Microsoft YaHei", "Segoe UI", "Lucida Grande", Helvetica, Arial,
    sans-serif;
}
a {
  color: rgba(255, 255, 255, 0.6);
  outline: 0;
  text-decoration: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
a:focus,
a:hover {
  color: #74777b;
  text-decoration: none;
}
.htmleaf-container {
  margin: 0 auto;
}
.bgcolor-1 {
  background: #f0efee;
}
.bgcolor-2 {
  background: #f9f9f9;
}
.bgcolor-3 {
  background: #e8e8e8;
}
.bgcolor-4 {
  background: #2f3238;
  color: #fff;
}
.bgcolor-5 {
  background: #df6659;
  color: #521e18;
}
.bgcolor-6 {
  background: #2fa8ec;
}
.bgcolor-7 {
  background: #d0d6d6;
}
.bgcolor-8 {
  background: #3d4444;
  color: #fff;
}
.bgcolor-9 {
  background: #ef3f52;
  color: #fff;
}
.bgcolor-10 {
  background: #64448f;
  color: #fff;
}
.bgcolor-11 {
  background: #3755ad;
  color: #fff;
}
.bgcolor-12 {
  background: #3498db;
  color: #fff;
}
.bgcolor-20 {
  background: #494a5f;
  color: #d5d6e2;
}
.htmleaf-header {
  padding: 1em 190px 1em;
  letter-spacing: -1px;
  text-align: center;
  background: #66677c;
}
.htmleaf-header h1 {
  color: #d5d6e2;
  font-weight: 600;
  font-size: 2em;
  line-height: 1;
  margin-bottom: 0;
}
.htmleaf-header h1 span {
  display: block;
  font-size: 60%;
  font-weight: 400;
  padding: 0.8em 0 0.5em 0;
  color: #c3c8cd;
}
.htmleaf-demo a {
  color: #fff;
  text-decoration: none;
}
.htmleaf-demo {
  width: 100%;
  padding-bottom: 1.2em;
}
.htmleaf-demo a {
  display: inline-block;
  margin: 0.5em;
  padding: 0.6em 1em;
  border: 3px solid #fff;
  font-weight: 700;
}
.htmleaf-demo a:hover {
  opacity: 0.6;
}
.htmleaf-demo a.current {
  background: #1d7db1;
  color: #fff;
}
.htmleaf-links {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  font-size: 1.5em;
  text-align: center;
}
.htmleaf-links::after {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #dbdbdb;
  content: "";
  -webkit-transform: rotate3d(0, 0, 1, 22.5deg);
  transform: rotate3d(0, 0, 1, 22.5deg);
}
.htmleaf-icon {
  display: inline-block;
  margin: 0.5em;
  padding: 0 0;
  width: 1.5em;
  text-decoration: none;
}
.htmleaf-icon span {
  display: none;
}
.htmleaf-icon:before {
  margin: 0 5px;
  text-transform: none;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  font-family: icomoon;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}
.htmleaf-footer {
  width: 100%;
  padding-top: 10px;
}
.htmleaf-small {
  font-size: 0.8em;
}
.center {
  text-align: center;
}
.has-feedback {
  text-align: left;
}
.has-feedback label {
  padding-left: 20px;
}
.related {
  color: #fff;
  background: #494a5f;
  text-align: center;
  font-size: 1.25em;
  padding: 0.5em 0;
  overflow: hidden;
}
.related > a {
  vertical-align: top;
  width: calc(80%);
  max-width: 340px;
  display: inline-block;
  text-align: center;
  margin: 20px 10px;
  padding: 25px;
  font-family: "Microsoft YaHei", "宋体", "Segoe UI", "Lucida Grande",
    Helvetica, Arial, sans-serif, FreeSans, Arimo;
}
.related a {
  display: inline-block;
  text-align: left;
  margin: 20px auto;
  padding: 10px 20px;
  opacity: 0.8;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-backface-visibility: hidden;
}
.related a:active,
.related a:hover {
  opacity: 1;
}
.related a img {
  max-width: 100%;
  opacity: 0.8;
  border-radius: 4px;
}
.related a:active img,
.related a:hover img {
  opacity: 1;
}
.related h3 {
  font-family: "Microsoft YaHei", sans-serif;
  font-size: 1.2em;
}
.related a h3 {
  font-size: 0.85em;
  font-weight: 300;
  margin-top: 0.15em;
  color: #fff;
}
.icon-htmleaf-home-outline:before {
  content: "\e5000";
}
.icon-htmleaf-arrow-forward-outline:before {
  content: "\e5001";
}
@media screen and (max-width: 1024px) {
  .htmleaf-header {
    padding: 2em 10% 2em;
  }
  .htmleaf-header h1 {
    font-size: 1.4em;
  }
  .htmleaf-links {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 960px) {
  .htmleaf-header {
    padding: 2em 10% 2em;
  }
  .htmleaf-header h1 {
    font-size: 1.2em;
  }
  .htmleaf-links {
    font-size: 1.2em;
  }
  .related h3 {
    font-size: 1em;
  }
  .related a h3 {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 766px) {
  .htmleaf-header h1 {
    font-size: 1.3em;
  }
  .htmleaf-links {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 640px) {
  .htmleaf-header {
    padding: 2em 10% 2em;
  }
  .htmleaf-header h1 {
    font-size: 1em;
  }
  .htmleaf-links {
    font-size: 1em;
  }
  .related h3 {
    font-size: 0.8em;
  }
  .related a h3 {
    font-size: 0.6em;
  }
}
.form-box {
  margin-top: 30px;
  margin-bottom: 30px;
}
.submit {
  margin-top: 10px;
}
.loading {
  width: 56px;
  height: 40px;
  margin: 0 auto;
}
.container {
  width: 960px;
  margin: 20px auto;
}
.form-bg {
  padding: 2em 0;
}
.form-horizontal {
  background: #fff;
  padding-bottom: 40px;
  border-radius: 15px;
  text-align: center;
}
.form-horizontal .heading {
  display: block;
  font-size: 35px;
  font-weight: 700;
  padding: 35px 0;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 30px;
}
.form-horizontal .form-control {
  background: #f0f0f0;
  border: none;
  border-radius: 20px;
  box-shadow: none;
  padding: 0 20px 0 20px;
  transition: all 0.3s ease 0s;
}
.help-block {
  padding-left: 20px;
}
.form-horizontal .form-control:focus {
  background: #e0e0e0;
  box-shadow: none;
  outline: 0 none;
}
.form-horizontal .form-control:focus + i {
  color: #00b4ef;
}
.form-horizontal .fa-question-circle {
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 60px;
  font-size: 20px;
  color: grey;
  transition: all 0.5s ease 0s;
}
.form-horizontal .fa-question-circle:hover {
  color: #000;
}
.form-horizontal .main-checkbox {
  float: left;
  width: 20px;
  height: 20px;
  background: #11a3fc;
  border-radius: 50%;
  position: relative;
  margin: 5px 0 0 5px;
  border: 1px solid #11a3fc;
}
.form-horizontal .main-checkbox label {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.form-horizontal .main-checkbox label:after {
  content: "";
  width: 10px;
  height: 5px;
  position: absolute;
  top: 5px;
  left: 4px;
  border: 3px solid #fff;
  border-top: none;
  border-right: none;
  background: 0 0;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.form-horizontal .main-checkbox input[type="checkbox"] {
  visibility: hidden;
}
.form-horizontal .main-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.form-horizontal .text {
  float: left;
  margin-left: 7px;
  line-height: 20px;
  padding-top: 5px;
  text-transform: capitalize;
}
.form-horizontal .btn {
  font-size: 14px;
  color: #fff;
  background: #ea0d49;
  border-radius: 30px;
  padding: 10px 25px;
  border: none;
  text-transform: capitalize;
  transition: all 0.5s ease 0s;
}
@media only screen and (max-width: 479px) {
  .form-horizontal .form-group {
    padding: 0 25px;
  }
  .form-horizontal .form-group i {
    left: 45px;
  }
  .form-horizontal .btn {
    padding: 10px 20px;
  }
}
.form-horizontal {
  padding: 0 40px;
  padding-bottom: 20px;
}
.form-group {
  margin: 0;
}
.form-group {
  margin-bottom: 20px;
}
.loading {
  width: 120px;
  height: 15px;
  margin: 0 auto;
}
.loading span {
  display: inline-block;
  width: 15px;
  height: 100%;
  margin-right: 5px;
  border-radius: 50%;
  background: #90ee90;
  -webkit-animation: load 1.04s ease infinite;
}
.loading span:last-child {
  margin-right: 0;
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading span:nth-child(1) {
  -webkit-animation-delay: 0.13s;
}
.loading span:nth-child(2) {
  -webkit-animation-delay: 0.26s;
}
.loading span:nth-child(3) {
  -webkit-animation-delay: 0.39s;
}
.loading span:nth-child(4) {
  -webkit-animation-delay: 0.52s;
}
.loading span:nth-child(5) {
  -webkit-animation-delay: 0.65s;
}
.loading-warp {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
}
.loading-warp .loading-box {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -60px;
  margin-top: -30px;
  z-index: 99;
}
.loading-warp .loading-box p {
  height: 20px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  margin-top: 24px;
}
.loading-warp .loading-mask {
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  background: #000;
  bottom: 0;
  filter: alpha(opacity=50);
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.mapboxgl-popup-content {
  overflow-y: auto;
  max-height: 300px;
  max-width: 750px;
}

.mapboxgl-ctrl-top-right {
  top: 0;
  right: 0;
  display: flex;
}

.mapboxgl-ctrl-group button {
  width: 29px;
  height: 36px;
  display: block;
  padding: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
}

.ant-upload-select-picture-card i {
  color: #999;
  font-size: 32px;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-upload-list-picture-card-container {
  float: left;
  width: 90px;
  height: 90px;
  margin-top: 9px;
  margin-right: 20px;
}
.ant-upload.ant-upload-select-picture-card {
  float: left;
  width: 90px;
  height: 90px;
  margin-top: 16px;
  margin-left: 0px;
}

.ant-comment-inner {
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
}
.ant-comment-actions {
  margin-top: 6px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}

.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
